// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
    accopeningUserPassAuthCheck: new Map([
        [
            'CLIENTWEB',
            [
                ['accOpeningUsername', 'ZavQiciaB5nsaRMMae85I6FcZQH6pElWBqXLM9cbEAw='], //accOpeningUsername
                ['accOpeningPassWord', 'Z0ZeQ7BXlRQ2caMze7qsmlq+tcq8S5cxQJMPmZaCaSE='], //accOpeningPassWord
                ['AuthrizationKey', 'casam3zeve4afgeqdxfeb8fce220212121'], //AuthrizationKey
                ['checkSumKey', '40416ZBag@Qxzheo!9477'] //checkSumKey
            ]
        ],
        [
            'SPOT',
            [
                ['accOpeningUsername', 'ix2OYeXODrPZukzBqyrfyspPmYlua530jU5o3thLftk='],
                ['accOpeningPassWord', '0YgAtI4tdAdkb6uqk9sbgLpn8kiYaqhZPGCJ+P4a8PI='],
                ['AuthrizationKey', 'XajPWfK6dLYQBYJ6r7tOghCzNF1brApV'],
                ['checkSumKey', 'fGpAd@w!N%@iMkKc']
            ]
        ],
        [
            'SPOTIOS',
            [
                ['accOpeningUsername', 'ix2OYeXODrPZukzBqyrfyspPmYlua530jU5o3thLftk='],
                ['accOpeningPassWord', '0YgAtI4tdAdkb6uqk9sbgLpn8kiYaqhZPGCJ+P4a8PI='],
                ['AuthrizationKey', 'YbkQXgL9eMbRaZK7s3uPkiDaMG2cdBrW'],
                ['checkSumKey', 'gHqBe@y!M%@jNlLd']
            ]
        ],
        [
            'PARTNERSHIPS',
            [
                ['accOpeningUsername', 'UoWJihikB1vjrQJKoj15L6VvCRH9pDlEBaKcY9SjEZw='],
                ['accOpeningPassWord', 'U5LeI7DQiPX1saTzw3nvqtg+lbd3R5jxQJYPiJaTaBE='],
                ['AuthrizationKey', 'PVbVNrbbcKcOd8UW2l2jVx8aTCYUOJeG'],
                ['checkSumKey', '1011P!BYQVNrbgmR62d8']
            ]
        ]
    ]),
    production: false,
    twoFACheck: 'N',
    appVer: '1.0',
    ttAPIHeaderKey: '4dddd59bdab77bace6189d001f96487e',
    osName: '5PWEB',
    gainerLoserrequestCode: '5PJanGLD',
    loginRequestCode: '5PLoginV5',
    secretKey: 'pnqm09pab35hkys07sdr06fk48hifa7degeFMUJFW7GBCW46686dfgjghfg1dh30',
    issuer: 'https://pwabeta.5paisa.com',
    apiHeaderKeyName: 'Ocp-Apim-Subscription-Key',
    apiHeaderKeyValue: '4a4121b00ee741bf88faf9e85048c3ce',
    paymentOcpApiHeaderKey: '4a4121b00ee741bf88faf9e85048c3ce',
    keyForSubscriptionPlans: '4a4121b00ee741bf88faf9e85048c3ce',
    authForSubscriptionPlans: '5PCRM-authtoken b01ef977ae5d658b4368dfsebe181cf5bd9deddf',
    gatewayKey: '20b999c638704f3ea252b1b5ced2eb4a',
    ocpActivation: '20b999c638704f3ea252b1b5ced2eb4a',
    diygatewayKey: '20b999c638704f3ea252b1b5ced2eb4a',
    marketFeedApiKey: '93622416a8b542ba948bb2c46b0f4d50',
    microChartTokenAPIHeader: 'aA6Y61KMFGTaekaweLS5jPGa9vXPl7/v5qV11eq8aC/IwewderzLcA==',
    microChartHeaderKey: '2284d4215515451ca7266097726d8097',
    twofaSubscriptionKey: '2284d4215515451ca7266097726d8097',
    webSocketTokenKeyValue: 'c89fab8d895a426d9e00db380b433027',
    jwtToken:
        'JwtToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjU0MzExNTY5Iiwicm9sZSI6IjEiLCJuYmYiOjE2NDczMjYwMTUsImV4cCI6MTY0OTkxODAxNSwiaWF0IjoxNjQ3MzI2MDE1fQ.UUQcmn7WRjl4qhELfx60zac8ttfTKSKrzFU4KRvDeEw',
    companyReturnsToken: 'a4af51382266497bb5464d95fbb2017b',
    companyReturnsBasicAuth: 'Basic aW5kaWFpbmZvbGluZVw1cGFuZHJvaWQ6MnhxUzV2L1g5Og==',
    ipoStatusPass: "Request sent to Investor's UPI App for Acceptance",
    cookie: '5paisacookie=6AE43F629BFC500568F0FE0AAC31076242FFEA7435CC9044ED1FE6F6AD54AF99DDFA28ABC5A776B00C303B2101684FBC6EDA6E842016E131B84701FDA0B664DAE989BBC348A020AFBA8F6939CBFCFCD9A645AC4CBE37BE887D84B8FB306B4D786A4959652CCF40E106B2A464D36D392CBC249755B60C8B06526337C9A196DAD343706EA95E720481B8847EA90A240C3797B942CF',
    csrftoken: 'csrftoken=bOaMGqNvTRpUvfwh70vQXznvW98xGQ3U',
    basicAuth: 'Basic QWxleGFBcGlVc2VyOiQzY2gwQXQ3MCFjZTk2',
    gatewayTradeApi: 'https://gateway.5paisa.in/tradeapi',
    authgatewatApi:'https://gateway.5paisa.in/AuthApi',
    baseTTapi: 'http://ttavatar.5paisa.in/5Paisa_Mobile_Service/Service1.svc',
    loggerApiUrl: 'http://localhost:68552/', // Replace with local API
    // loggerApiUrl: undefined, // Replace with local API
    ttAPI: 'http://ttavatar.5paisa.in/5paisa_mobile_service/service1.svc',
    logLevel: 0,
    serverLogLevel: 7,
    baseAPI: 'https://gateway.5paisa.in/pwaspot',
    gatewayBaseAPI: 'https://gateway.5paisa.in/pwaspot',
    baseAPINew: 'https://gateway.5paisa.in/',
    loginBaseUrl: 'https://uatapi.5paisa.in/',
    getwayMarketFeedApi: 'https://gateway.5paisa.in/',
    // getwayAPI: 'https://gateway.5paisa.com/5pactivation',
    baseAPIActivation: 'https://gateway.5paisa.in/5peaccountuat/',
    getMicroChartAPI: 'https://minichart.5paisa.com/chartimage/',
    getMicroChartTokenAPI: 'https://charts-auth.5paisa.com/api/ChartToken',
    accopeningchecksumkey: '40416ZBag@Qxzheo!9477',
    accopeningapiusername: 'ZavQiciaB5nsaRMMae85I6FcZQH6pElWBqXLM9cbEAw=',
    accopeningapipassword: 'Z0ZeQ7BXlRQ2caMze7qsmlq+tcq8S5cxQJMPmZaCaSE=',

    accOpeningAPI: 'https://gateway.5paisa.in/5peaccountuat/',
    accOpeningAuthenticationKey: 'casam3zeve4afgeqdxfeb8fce220212121',
    accOpeningAuthenticationSpotKey: 'XajPWfK6dLYQBYJ6r7tOghCzNF1brApV',
    getCompanyReturnsAPI: 'https://gateway.5paisa.com/tradeapi/company-performance',
    getPartnerDetails: 'https://paisauat.firebaseio.com/db/PWA/',
    chartToken:
        'sv=2019-12-12&st=2022-04-08T06%3A18%3A46Z&se=2022-04-08T07%3A18%3A46Z&sr=c&sp=r&sig=v9D07uibyxr0uylXgm9e9zZs746w8Pxo1cA%2B37U11Vo%3D',
    digioURL: 'https://ext.digio.in/#/gateway/login/',
    // digioCallbackUrl : "http://localhost:63555/activation/add-bank",
    digioCallbackUrl: 'https://pwaweb-uat.5paisa.com/activation/digilocker/success',
    // digioCallbackUrl: 'https://pwaweb-uat.5paisa.com/activation/add-bank',
    webSocketTokenAPI: 'https://openapi.5paisa.com/marketfeed-token/token',
    geoLocationUrl: 'https://api.ipgeolocation.io/ipgeo?apiKey=d7a00180faa14d5b89906d9710e6bfdd',
    EaccountLoginUrl: 'https://eauatv3.5paisa.com/ClientLogin/VerifyClientLoginMobile?',
    paymentEndPointUrl : 'https://gateway.5paisa.in/Payment/PayIn',
    //add subscription
    subscriptionChecksumKey: 'sFQipBjWQZlpzahfukkbJDOAdF4Q3WT0pyHA+JdRvMS773WuppUSThALZCxvNJUWYACmj6xMahhxIfW0L9MKhQ==',

    //added variables
    ipoKey: 'fd*$fdfd4855#wqscg$7cx48ip1wrv',
    activationEncryptionKey: 'IIFV2SPBNI11888',
    ipoProduct: 'IPO',
    ipoSourcedBy: 'Web',
    AppSource: '15',
    appSourcedBy: 'SPOT',
    fivePaisaWebApiKey: 'kahdAjhfdsd423jkoi3frDFD3vbxvzyXZ4S4dF',
    pgMerchantId: 'HDFC000000625458',
    fivePHMAC256Key: 'SzU+OuwdvdOFubj4LyYOGsSP2b01VavRKtmChz3L2cQMMrs/EJbujjUwfxUgSK/EU/996pU4BiI/Ik6IEFY/ig==',
    fivePaisaApiAuthKey: 'NXBhaXNhOjVwYWlzYUB3ZWI=',
    firebase: {
        apiKey: 'AIzaSyCW7oaHrnZt7MmDfEEQ3Z5oEG3N7lsOJNA',
        authDomain: 'paisauat.firebaseapp.com',
        databaseURL: 'https://paisauat.firebaseio.com',
        projectId: 'paisauat',
        storageBucket: 'paisauat.appspot.com',
        messagingSenderId: '423363724376',
        appId: '1:423363724376:web:2875f473722a6a3358762d',
        measurementId: 'G-L9P0GEEKBV'
    },
    loginRedirection: 'https://blogin.5paisa.com',
    signalSwotId: '5PAISAAPI',
    signalSwotpassword: '5nadynsiitnienny',
    signalSwotrequestCode: '5paisaapi',
    signalSwotKey: '5260c06e20fb53c4521b8cf1f2eb0ba616634e44',
    baseDomain: 'https://pwaweb-uat.5paisa.com/',
    // Added By Sachin Devkar
    //pwaNodeAPI: 'http://127.0.0.1:6001/api/v1/SSO/',
    pwaNodeAPI: 'https://pwauatapi.5paisa.in/api/v1/SSO/',
    //pwaNodeAPI: 'http://localhost:4001/api/v1/SSO/',
    pwaNodeAPIUser: 'pwauatapi',
    pwaNodeAPIPass: 'pwauatapi',
    ActivationURL: 'https://pwaweb-uat.5paisa.com/activation/mobile-verification',
    accountopeningspotusername: 'ix2OYeXODrPZukzBqyrfyspPmYlua530jU5o3thLftk=',
    accountopeningspotuserpassword: '0YgAtI4tdAdkb6uqk9sbgLpn8kiYaqhZPGCJ+P4a8PI=',
    spotAuthenticationKey: 'XajPWfK6dLYQBYJ6r7tOghCzNF1brApV',
    accopeningchecksumkeyspot: 'fGpAd@w!N%@iMkKc',
    basicAuthGuard:
        'Basic aXgyT1llWE9EclBadWt6QnF5cmZ5c3BQbVlsdWE1MzBqVTVvM3RoTGZ0az06MFlnQXRJNHRkQWRrYjZ1cWs5c2JnTHBuOGtpWWFxaFpQR0NKK1A0YThQST0=',
    getBankdetailsVariable: 'Amount',
    updateEmailVersion: '/UpdateNewEmailMobile_V3',
    experianLogUrl: 'https://pwauatapi.5paisa.in/api/v1/SSO/CaptureAPIDetails',
    iosspotchecksumkey: 'gHqBe@y!M%@jNlLd',
    iosaccountopeningspotusername: 'ix2OYeXODrPZukzBqyrfyspPmYlua530jU5o3thLftk=',
    iosaccountopeningspotpassword: '0YgAtI4tdAdkb6uqk9sbgLpn8kiYaqhZPGCJ+P4a8PI=',
    iosSpotAuthenticationKey: 'YbkQXgL9eMbRaZK7s3uPkiDaMG2cdBrW',
    encodingKey: 'pnqm09pab35hkys07sdr06fk48hifa7degeFMUJFW7GBCW46686dfgjghfg1dh30',
    redirectUrl: 'https://pwabeta.5paisa.com/SSO',
    IsRazorpayActive: 'Y',
    authgatewayOcpkey:'1dfb4dcb9ea345408e13342d7efb1ea2',
    ipoGatewayKey: '3694a376dc514d3388a22372eced4988',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
