import { environment } from 'src/environments/environment';
import { loadRemoteEntry } from '@angular-architects/module-federation';

Promise.all([
    console.log('main'),

    //loadRemoteEntry({ type: 'module', remoteEntry: 'http://localhost:3000/remoteEntry.js' }),
    loadRemoteEntry({ type: 'module', remoteEntry: environment.baseDomain + 'trading-pwa/remoteEntry.js' }),
    loadRemoteEntry({ type: 'module', remoteEntry: environment.baseDomain + 'activation-pwa/remoteEntry.js' }),
    loadRemoteEntry({ type: 'module', remoteEntry: environment.baseDomain + 'ipo/remoteEntry.js' }),
    loadRemoteEntry({ type: 'module', remoteEntry: environment.baseDomain + 'others/remoteEntry.js' }),
    loadRemoteEntry({ type: 'module', remoteEntry: environment.baseDomain + 'mutualfund-pwa/remoteEntry.js' })
])
    .catch((err) => console.error('Error loading remote entries', err))
    .then(() => import('./bootstrap'))
    .catch((err) => console.error(err));
// import('./bootstrap')
// .catch(err => console.error(err));
